import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";
import smallLogo from "./smallLogo.jpg";
import largeLogo from "./largeLogo.jpg";
import fullLogo from "./fullLogo.jpg";
import land from "./land.jpeg";
import { IoMenu } from "react-icons/io5";
import houses from "./houses.png";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBGwEtoh56Wst8V_Pct7KpGeHjrVSFKoF0",
  authDomain: "walkerandfriends-8d98f.firebaseapp.com",
  projectId: "walkerandfriends-8d98f",
  storageBucket: "walkerandfriends-8d98f.appspot.com",
  messagingSenderId: "298243361266",
  appId: "1:298243361266:web:077571514d94f837b6c36b",
  measurementId: "G-VK93K90F4X",
};

function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [menu, setMenu] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const theme = {
    primary: "grey",
  };
  const Header = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    background: white;
    z-index: 99999;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    /* border: 1px solid red; */

    .container {
      display: flex;
      padding: 0.1em 0.5em;
      justify-content: space-between;
      align-items: center;
      color: ${theme.primary};
      @media only screen and (min-width: 600px) {
        padding: 0em 3em;
      }
      img {
        height: 5em;
        @media only screen and (min-width: 600px) {
          height: 5.1em;
        }
      }

      .menu {
        width: fit-content;

        svg {
          font-size: 1.5em;
          margin-right: 0.4em;
          cursor: pointer;
          @media only screen and (min-width: 600px) {
            display: none;
          }
        }

        .main {
          display: none;
          color: black;
          cursor: pointer;
          color: inherit;
          text-decoration: none;
          @media only screen and (min-width: 600px) {
            display: inline-block;
            margin-left: 1.5em;
          }
        }

        .dropdown {
          position: absolute;
          left: 18%;
          width: 60%;
          background: white;
          top: 5em;
          display: flex;
          flex-direction: column;
          padding: 0.2em 1em;
          border-radius: 5px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08),
            0 2px 4px 0 rgba(0, 0, 0, 0.12);

          a {
            padding: 0.5em 0;
            border-bottom: 1px solid lightgrey;
            cursor: pointer;
            color: inherit;
            text-decoration: none;
          }
          @media only screen and (min-width: 600px) {
            display: none;
          }

          :last-child {
            border-bottom: none;
          }
        }
      }
    }
  `;

  const Footer = styled.div`
    border-top: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
    margin: 0 5%;
    margin-bottom: 1em;
    width: 90%;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    @media only screen and (min-width: 1100px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
    /* font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif; */
    img {
      /* width: 10em; */
      @media only screen and (min-width: 600px) {
        height: 5.1em;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media only screen and (min-width: 1100px) {
        margin-right: 1.5em;
        flex-direction: row;
      }
      span {
        @media only screen and (min-width: 600px) {
          margin-left: 1em;
        }
      }
    }
    .largeLogo {
      height: 5em;
      display: block;
      @media only screen and (min-width: 600px) {
        display: block;
      }
    }
    .smallLogo {
      height: 4em;
      display: block;
      @media only screen and (min-width: 600px) {
        display: block;
      }
    }

    span {
      font-size: 12px;
      @media only screen and (min-width: 1100px) {
        font-size: 14px;
      }
    }
  `;

  const Home = ({ place = "home" }) => {
    const Hero = styled.div`
      width: 100%;
      margin-top: 5.1em;
      position: relative;
      height: fit-content;

      img {
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }

      .container {
        color: white;
        height: 100%;
        position: relative;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 0 10%;

        padding-bottom: 3em;
        @media only screen and (min-width: 800px) {
          display: flex;
          align-items: center;
        }

        h1 {
          margin: 0;
          margin-bottom: 1em;
          padding-top: 2em;
          font-size: 35px;
          font-weight: 800;

          @media only screen and (min-width: 600px) {
            font-size: 50px;
            padding-top: 1em;
          }

          .R {
            font-size: 22px;
            margin-left: 0.2em;
          }
        }
        p {
          margin: 0;
          margin-bottom: 1em;
          font-size: 16px;
          font-weight: 500;
          @media only screen and (min-width: 600px) {
            font-size: 25px;
          }
        }

        form {
          color: grey;
          background: white;
          border-radius: 5px;
          padding: 2em 2em;
          margin-top: 2em;
          @media only screen and (min-width: 800px) {
            min-width: 30%;
            margin-left: 10%;
            margin-top: 3em;
          }

          .terms {
            display: flex;
            flex-direction: row;
            justify-content: start;

            input {
              height: 1em;
              width: 2em;
              margin-right: 0.4em;
              background-color: steelblue;
            }

            input:checked {
              background-color: steelblue;
            }

            p {
              padding: 0;
              margin: 0;
              text-align: left;
            }
          }
          h2 {
            margin: 0;
            padding: 0;
            color: black;
            padding-bottom: 0.5em;
          }
          input {
            padding: 0;
            margin: 0;
            width: 95%;
            height: 45px;
            background: #f2f2f2;
            outline: none;
            border: 1px solid grey;
            border-radius: 3px;
            font-size: 20px;
            padding-left: 0.5em;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            margin-bottom: 1em;
            span {
              margin-bottom: 0.5em;
              font-weight: bold;

              @media only screen and (min-width: 600px) {
                white-space: nowrap;
              }
            }
          }

          p {
            font-size: small;
          }
          a {
            color: steelblue;
          }

          .button {
            margin-top: 1em;
            font-size: 1.3em;
            font-weight: bold;
            border: 1px solid steelblue;
            color: white;
            background: steelblue;
            width: 100%;
            padding: 0;
          }
        }

        .thanks {
          color: black;
          strong {
            font-size: 20px;
          }
        }
      }
    `;

    const About = styled.div`
      padding: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
      @media only screen and (min-width: 600px) {
        width: 85%;
      }
      img {
        object-fit: contain;
        width: 90%;
        margin-bottom: 2em;
        @media only screen and (min-width: 1100px) {
          width: 50%;
        }
      }
      .seperator {
        height: 0.35em;
        width: 3.5em;
        background: steelblue;
        margin-top: 3em;
      }
      h1 {
        margin: 0;
        margin-bottom: 2em;
        padding-top: 1em;
        font-size: 25px;
        font-weight: 800;

        .R {
          font-size: 22px;
          margin-left: 0.2em;
        }

        @media only screen and (min-width: 600px) {
          font-size: 35px;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: 1100px) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
      p {
        width: 85%;
        margin: 0;
        margin-bottom: 1em;
        font-size: 18px;
        font-weight: 700;
        @media only screen and (min-width: 800px) {
          font-size: 20px;
        }
        @media only screen and (min-width: 1100px) {
          width: 40%;
          font-size: 20px;
        }

        .R {
          font-size: 14px;
          margin-left: 0.2em;
        }
      }
    `;

    const Wrapper = styled.div`
      display: flex;
      align-items: center;
      flex-direction: ${place == "about" ? "column-reverse" : "column"};
      margin-top: ${place == "about" ? "5em" : "0em"};
    `;
    return (
      <Wrapper>
        <Hero>
          <img src={land}></img>
          <div className="container">
            <div>
              <h1>Sell your house today with Walker & Friends</h1>
              <p>
                Seamlessly sell your house fast here. No realtors needed, there
                are no commissions or hidden fees, and we’ll even pay for your
                closing costs. Get your free, no-obligation offer on your
                property by filling out the form or calling us.
              </p>
            </div>
            {!submitted ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let inputs = e.target.elements;

                  let info = {
                    name: inputs["name"].value,
                    email: inputs["email"].value,
                    number: inputs["number"].value,
                    address: inputs["address"].value,
                    consent: inputs["consent"].value,
                  };

                  try {
                    addDoc(collection(db, "formSubmissions"), info).then(() => {
                      setSubmitted(true);
                    });
                  } catch (e) {
                    console.error("Error adding document: ", e);
                  }
                  return false;
                }}
              >
                <div>
                  <h2>Contact Us</h2>
                </div>
                <div>
                  <span>Name</span>
                  <input name="name" type="text" required></input>
                </div>
                <div>
                  <span>E-Mail</span>
                  <input name="email" type="email" required></input>
                </div>
                <div>
                  <span>Phone Number</span>
                  <input name="number" type="text" required></input>
                </div>
                <div>
                  <span>Property Address</span>
                  <input name="address" type="text" required></input>
                </div>

                <div className="terms">
                  <input name="consent" type="checkbox" required></input>
                  <p>
                    I have read and agree to the{" "}
                    <a href="/privacy-policy">Privacy Policy</a> and{" "}
                    <a href="/terms-of-service">Terms and Conditions.</a>
                  </p>
                </div>
                <p style={{ textAlign: "left", marginLeft: "0.5em" }}>
                  By submitting the contact form and signing up for texts, you
                  consent to receive marketing text messages from Walker &
                  Friends at the number provided. Consent is not a condition of
                  purchase. Message frequency varies. Message and data rates may
                  apply. You can unsubscribe at any time by replying STOP. Text
                  HELP to get help. Please read our{" "}
                  <a href="/privacy-policy">Privacy Policy</a> and for more
                  details.
                </p>
                <div>
                  <input className="button" type="submit"></input>
                </div>
              </form>
            ) : (
              <form className="thanks">
                <span>
                  <strong>Thank You For Choosing Walker & Friends! </strong>
                  <br></br> <br></br>A member of our team will be in contact
                  with you soon. If you need to speak to us sooner, please
                  contact us now at info@walkerandfriends.com.
                </span>
              </form>
            )}
          </div>
        </Hero>
        <About>
          <div className="seperator"></div>
          <h1>Walker & Friends LLC – Discover Peace of Mind</h1>
          <div>
            <img src={houses}></img>
            <p>
              Walker & Friends stands proudly among the nation’s finest home
              buying companies, driven by our unwavering commitment to
              homeowners. Our most important goal is to provide a
              straightforward, seamless, and quick selling experience to our
              sellers.
              <br></br>
              <br></br>
              With Walker & Friends, selling your home is not just a
              transaction—it’s a hassle-free journey towards your next chapter.
            </p>
          </div>
        </About>
      </Wrapper>
    );
  };

  const TermsOfService = () => {
    const Wrapper = styled.div`
      padding: 7.1em 7% 0em 7%;
      text-align: left;
      .section {
        margin-bottom: 2em;
        .subTitle {
          font-weight: bold;
        }
      }

      @media only screen and (min-width: 600px) {
        padding: 7.1em 7% 0em 7%;
      }

      .header {
        margin: 1em 0;
        font-size: 14px;

        @media only screen and (min-width: 600px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        h1 {
          font-weight: 500;

          .R {
            font-size: 17px;
            margin-left: 0.2em;
          }
        }
      }
    `;
    return (
      <Wrapper>
        <div className="header">
          <h1>Walker & Friends LLC</h1>
          <h1>Messaging Terms & Conditions</h1>
        </div>

        <div className="section">
          <span className="subTitle">Our Commitment to Privacy</span>
          <p>
            You agree to receive recurring automated promotional and
            personalized marketing text (e.g., SMS and MMS) messages (e.g. cart
            reminders) from Walker & Friends LLC including text messages that
            may be sent using an automatic telephone dialing system, to the
            mobile telephone number you provided when signing up or any other
            number that you designate. Consent to receive automated marketing
            text messages is not a condition of any purchase. Msg & Data rates
            may apply.
            <br></br>
            <br></br>
            Message frequency will vary. Walker & Friends LLC reserves the right
            to alter the frequency of messages sent at any time, so as to
            increase or decrease the total number of sent messages. Walker &
            Friends LLC also reserves the right to change the short code or
            phone number from which messages are sent and we will notify vou
            when we do so.
            <br></br>
            <br></br>
            Not all mobile devices or handsets may be supported and our messages
            may not be deliverable in all areas. Walker & Friends LLC, its
            service providers, and the mobile carriers supported by the program
            are not liable for delayed or undelivered messages.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Cancellation</span>
          <p>
            Text the keywords STOP, END, CANCEL, UNSUBSCRIBE or QUIT to cancel.
            After texting STOP, END, CANCEL, UNSUBSCRIBE, or QUIT you will
            receive one additional message confirming that your request has been
            processed. You acknowledge that our text message platform may not
            recognize and respond to unsubscribe requests that do not include
            the STOP, END, CANCEL, UNSUBSCRIBE, or QUIT keyword commands and
            agree that Launch Control and its service providers will have no
            liability for failing to honor such requests.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Help</span>
          <p>
            Text the keyword HELP to return customer care contact information.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Opt-In for Marketing Text Messages</span>
          <p>
            By submitting the contact form and signing up for texts, you consent
            to receive marketing text messages from Walker & Friends LLC at the
            number provided. Consent is not a condition of purchase. Message and
            data rates may apply. You can unsubscribe at any time by replying
            STOP to the received SMS (texts) or clicking the unsubscribe link
            (where available) in the marketing text messages.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Other Terms</span>
          <p>
            These Messaging Terms & Conditions are a part of and subject to our
            Terms of Use and our Privacy Policy (collectively with these
            Messaging Terms & Conditions, the "Terms Documents"). By
            participating in our text message program, you agree to our Terms
            Documents. To the extent that a conflict exists between these
            Messaging Terms & Conditions and our Terms of Use, these Messaging
            Terms & Conditions will control.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Customer Care and Contact</span>
          <p>
            If you are experiencing any problems, please contact us at
            info@walkerandfriends.com
          </p>
        </div>
      </Wrapper>
    );
  };

  const PrivacyPolicy = () => {
    const Wrapper = styled.div`
      padding: 7.1em 7% 0em 7%;
      text-align: left;
      .section {
        margin-bottom: 2em;
        .subTitle {
          font-weight: bold;
        }
      }

      @media only screen and (min-width: 600px) {
        padding: 7.1em 7% 0em 7%;
      }

      .header {
        margin: 1em 0;
        font-size: 14px;

        @media only screen and (min-width: 600px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        h1 {
          font-weight: 500;

          .R {
            font-size: 17px;
            margin-left: 0.2em;
          }
        }
      }
    `;
    return (
      <Wrapper>
        <div className="header">
          <h1>Walker & Friends LLC</h1>
          <h1>Privacy Policy</h1>
        </div>

        <div className="section">
          <span className="subTitle">Our Commitment to Privacy</span>
          <p>
            Walker & Friends LLC ("we," "our," or "us") is committed to
            protecting the privacy and security of your personal information.
            This Privacy Policy outlines the types of information we collect
            from you, how we use and protect that information, and your rights
            regarding your personal data. By using our website and services, you
            consent to the practices described in this policy.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Information We Collect</span>
          <p>
            When you visit our website or use our services, we may collect
            certain types of information: <br></br> <br></br> Personal
            Information: We may collect personal information, such as your name,
            email address, phone number, and other contact details, only when
            you voluntarily provide it to us through forms or other means on our
            website.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">How We Use Your Information</span>
          <p>
            We use the information we collect for the following purposes:
            <br></br>
            <br></br>
            To facilitate property transactions: The property-related
            information we collect is used to complete the buying process,
            including property assessments and necessary documentation.
            <br></br>
            <br></br>
            To improve our services: We may use the information to enhance and
            customize our services, website content, and user experience.
            <br></br>
            <br></br>
            To communicate with you: We may use your contact information to
            respond to your inquiries, provide updates on property purchases,
            and share relevant information.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Opt-In for Marketing Text Messages</span>
          <p>
            By submitting the contact form and signing up for texts, you consent
            to receive marketing text messages from Walker & Friends LLC at the
            number provided. Consent is not a condition of purchase. Message and
            data rates may apply. You can unsubscribe at any time by replying
            STOP to the received SMS (texts) or clicking the unsubscribe link
            (where available) in the marketing text messages.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">
            Opt-In Information and Third-Party Sharing
          </span>
          <p>
            We respect your privacy choices, especially concerning the sharing
            of your personal information. Any information you provide through
            opt-in consent will not be shared with third parties for marketing
            or promotional purposes.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Data Security</span>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, alteration, disclosure, or destruction.
            However, no method of transmission over the Internet or electronic
            storage is entirely secure, and we cannot guarantee absolute
            security.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Your Rights</span>
          <p>
            You have the right to access, update, correct, and delete your
            personal information we hold. If you wish to exercise any of these
            rights, please contact us at info@walkerandfriends.com.
          </p>
        </div>

        <div className="section">
          <span className="subTitle">Changes to this Privacy Policy</span>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. The updated policy will be posted on our
            website, and the effective date will be revised accordingly.
          </p>
        </div>
        <div className="section">
          <span className="subTitle">Contact Us</span>
          <p>
            If you have any questions, concerns, or requests related to this
            Privacy Policy, please contact us at info@walkerandfriends.com.
          </p>
        </div>
      </Wrapper>
    );
  };
  return (
    <div className="App">
      <Header>
        <div className="container">
          <img src={largeLogo}></img>
          <div className="menu">
            <div
              onClick={() => {
                setMenu(!menu);
              }}
            >
              <IoMenu />
            </div>

            <a className="main" href="/">
              Home
            </a>
            <a className="main" href="/about">
              About
            </a>
            <a className="main" href="/">
              Get An Offer
            </a>

            {menu && (
              <div
                className="dropdown"
                onClick={() => {
                  setMenu(false);
                }}
              >
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="/">Get An Offer</a>
              </div>
            )}
          </div>
        </div>
      </Header>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home></Home>}></Route>
          <Route
            exact
            path="/about"
            element={<Home place="about"></Home>}
          ></Route>
          <Route
            exact
            path="/terms-of-service"
            element={<TermsOfService></TermsOfService>}
          ></Route>
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicy></PrivacyPolicy>}
          ></Route>
          <Route exact path="*" element={<Home></Home>}></Route>
        </Routes>
      </BrowserRouter>
      <Footer>
        <div className="logo">
          <img className="largeLogo" src={largeLogo}></img>
          <img className="smallLogo" src={smallLogo}></img>
        </div>

        <div className="text">
          <span>Copyright © Walker & Friends LLC All Rights Reserved</span>
          <span>
            <a href="/privacy-policy">View Our Privacy Policy</a> |{" "}
            <a href="/terms-of-service">View Our Terms of Service</a>
          </span>
        </div>
      </Footer>
    </div>
  );
}

export default App;
